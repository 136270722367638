.top-10 {
  list-style: none;
  counter-reset: rank;
  padding: 0;

  & > div > article {
    counter-increment: rank;
    height: 5em;
    position: relative;

    &::before {
      content: counter(rank);
      font-weight: bold;
      font-size: 1em;
      background: $color-f13;
      color: #000;
      width: 1.5em;
      height: 1.5em;
      border-radius: 2em;
      text-align: center;
      display: block;
      position: absolute;
      padding-top: 0px;
      margin-left: -10px;
      margin-top: -5px;
    }
  }
}
